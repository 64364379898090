<template>
  <div class="container content">
    <div class="headline">
      <h1>{{ $t('message.education.add.header') }}</h1>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-4">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.education.add.instructions.header') }}</p>
          <div v-html="$t('message.education.add.instructions.txt')"></div>
        </article>
     </div>
      <div class="tile is-parent is-8">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.education.add.form.header') }}</p>
          <div class="columns">
           <div class="column is-full">
               <b-field :label="$t('message.education.add.form.name')">
                  <b-input v-model="newEducation.name"></b-input>
              </b-field>
              <b-field :label="$t('message.education.add.form.category')">

                <b-radio-button v-model="newEducation.type"
                    native-value="koulutus"
                    type="is-primary is-light is-outlined">
                    <b-icon icon="check"></b-icon>
                    <span>{{ $t('message.education.add.form.type.education') }}</span>
                </b-radio-button>
                
                <b-radio-button v-model="newEducation.type"
                    native-value="esittely"
                    type="is-primary is-light is-outlined">
                    <b-icon icon="check"></b-icon>
                    <span>{{ $t('message.education.add.form.type.intro') }}</span>
                </b-radio-button>

               </b-field>
               <b-field :label="$t('message.education.add.form.category')">
                 <b-autocomplete
                      @typing="(txt) => {
                        
                        updateEducationCategory(txt)
                        updateSuggestions(txt)
                      }"
                      @select="option => {
                        updateEducationCategory(option.name)
                      }"
                      :keep-first="false"
                      :open-on-focus="false"
                      :data="suggestCategories"
                      field="name" />
              </b-field>
              <b-field :label="$t('message.education.add.form.description')">
                <b-input maxlength="1200" type="textarea" v-model="newEducation.description">></b-input>
              </b-field>
              
              <b-field :label="$t('message.education.add.form.image')">
                  <div class="control is-clearfix">
                    <b-upload
                        v-model="newEducation.logo"
                        class="file-label"
                    >
                        <span class="file-cta">
                            <b-icon
                                class="file-icon"
                                icon="upload"
                            ></b-icon>
                            <span class="file-label">{{
                                $t(
                                    "message.register-company.add.company.logo.load-here"
                                )
                            }}</span>
                        </span>
                        <span
                            class="file-name"
                            v-if="newEducation.logo.name"
                        >
                            {{ newEducation.logo.name }}
                        </span>
                    </b-upload>
                  </div>
              </b-field>

              <b-field :label="$t('message.education.add.form.link')">
                <b-input v-model="newEducation.link"></b-input>
              </b-field>

              <div class="columns" v-if="newEducation.type == 'koulutus'">
                <div class="column is-half">
                  <b-field :label="$t('message.education.add.form.price')">
                      <b-input maxlength="10" has-counter=false v-model="newEducation.price"></b-input>
                  </b-field>
                </div>
                <div class="column is-half">
                  <b-field :label="$t('message.education.add.form.pax-limit')">
                      <b-input maxlength="10" has-counter=false v-model="newEducation.pax_limit"></b-input>
                  </b-field>
                </div>
              </div>
              
              <div class="columns" v-if="newEducation.type == 'koulutus'">
                <div class="column is-half">
                  <section>
                    <b-field :label="$t('message.education.add.form.register-start')">
                      <b-datepicker
                        ref="datepicker"
                        :month-names="['tammi','helmi','maalis','huhti','touko','kesä','heinä','elo','syys','loka','marras','joulu']"
                        :day-names="['ma','ti','ke','to','pe','la','su']"
                        :date-formatter="_formatDate"
                        v-model="newEducation.register_start">
                      </b-datepicker>
                    </b-field>
                  </section>
                </div>

                <div class="column is-half">
                  <section>
                    <b-field :label="$t('message.education.add.form.register-end')">
                      <b-datepicker
                        ref="datepicker"
                        :month-names="['tammi','helmi','maalis','huhti','touko','kesä','heinä','elo','syys','loka','marras','joulu']"
                        :day-names="['ma','ti','ke','to','pe','la','su']"
                        :date-formatter="_formatDate"
                        v-model="newEducation.register_end">
                      </b-datepicker>
                    </b-field>
                  </section>
                </div>
              </div>

              <div class="columns"  v-if="newEducation.type == 'koulutus'">
                <div class="column is-half">
                  <section>
                    <b-field :label="$t('message.education.add.form.date-start')">
                      <b-datepicker
                        ref="datepicker"
                        :month-names="['tammi','helmi','maalis','huhti','touko','kesä','heinä','elo','syys','loka','marras','joulu']"
                        :day-names="['ma','ti','ke','to','pe','la','su']"
                        :date-formatter="_formatDate"
                        v-model="newEducation.course_start">
                      </b-datepicker>
                    </b-field>
                  </section>
                </div>
                <div class="column is-half">
                <section>
                  <b-field :label="$t('message.education.add.form.date-end')">
                    <b-datepicker
                      ref="datepicker"
                      :month-names="['tammi','helmi','maalis','huhti','touko','kesä','heinä','elo','syys','loka','marras','joulu']"
                      :day-names="['ma','ti','ke','to','pe','la','su']"
                      :date-formatter="_formatDate"
                      v-model="newEducation.course_end">
                    </b-datepicker>
                  </b-field>
                </section>
                </div>
              </div>

             </div>
            </div>
            <b-button
                :loading="false"
              :label="$t('message.partner.add.send')"
              size="is-large"
              class="fullwidth"
              type="is-success" 
              v-on:click="createEducation" />
        </article>
     </div>
    </div>  
  </div>
</template>

<script>
export default {
    data() {
        return {
            suggestCategories: [],
            newEducation: {
                name: '',
                type: 'koulutus', // esittely
                description: '',
                price: 0,
                pax_limit: 0,
                category: "",
                link: "",
                course_start: this.$moment()._d,
                course_end: this.$moment()._d,
                register_start: this.$moment()._d,
                register_end: this.$moment()._d,
                logo: "", 
                img_url: ""
            },
            errors: {
                is_error: false,
                name: false,
                description: false,
                price: false,
                pax_limit: false,
                course_start: false,
                course_end: false,
                register_start: false,
                register_end: false,
            },
            burger: false,
            isComponentModalActive: false,
            formProps: {
                email: 'evan@you.com',
                password: 'testing'
            }
        }
    },
    methods: {
        updateEducationCategory(txt) {
          this.newEducation.category = txt
        },
        createEducation2(categoryId) {
            var real_education = {
                name: this.newEducation.name,
                type: this.newEducation.type,
                description: this.newEducation.description,
                link: this.newEducation.link,
                price: this.newEducation.price,
                pax_limit: this.newEducation.pax_limit,
                category_id: categoryId,
                education_partner_id: this.user.education_partner_id,
                course_start: this.$moment(this.newEducation.course_start).format('YYYY-MM-DD'),
                course_end: this.$moment(this.newEducation.course_end).format('YYYY-MM-DD'),
                register_start: this.$moment(this.newEducation.register_start).format('YYYY-MM-DD'),
                register_end: this.$moment(this.newEducation.register_end).format('YYYY-MM-DD'),
                img_url: this.newEducation.img_url
            }
            console.log("creating education", real_education);
            this.$store.dispatch("createEducation", real_education).then((response) => {
                var status = response.status
                if (status >= 200 && status < 300) {
                    this.$router.push('/koulutukset')
                } else {
                    this.toastFailure(this.$t('message.education.add.toaster.creationError'));
                }
            }).catch((error) => {
                this.ERROR(error);
                this.toastFailure(this.$t('message.education.add.toaster.creationError'));
            });
        },
        createEducation() {

            // create category first
            var categoryName = this.newEducation.category

            var promises = []
            var promise = this.$store.dispatch("fetchCategories2")
            promises.push(promises)

            promise.then((response) => {
              var ff = response.data.filter(comp => {
                return (
                    comp.name
                        .toString()
                        .toLowerCase()
                        .indexOf(categoryName.toLowerCase()) >= 0
                )
              })
              if (ff.length == 0) {
                // category doesn't exist yet
                var createCategory = {
                  name: categoryName
                }
                var p2 = this.$store.dispatch("createCategory", createCategory)
                promises.push(p2)
                p2.then((response) => {
                  var status = response.status
                  if (status >= 200 && status < 300) {
                      console.log("created", createCategory)
                  } else {
                      this.toastFailure(this.$t('message.education.add.toaster.creationError'));
                  }
                }).catch((error) => {
                    this.ERROR(error);
                    this.toastFailure(this.$t('message.education.add.toaster.creationError'));
                });
              }
            });

            Promise.all(promises).then(() => {
              var that = this;

              setTimeout(function(){                 
                that.$store.dispatch("fetchCategories2").then((response) => {
                  var ff = response.data.filter(comp => {
                  return (
                      comp.name
                          .toString()
                          .toLowerCase()
                          .indexOf(categoryName.toLowerCase()) >= 0
                  )})

                  console.log("education partner user", that.user);
                  console.log("partners", that.partners);

                  var logo = that.newEducation.logo
                  var uploadImg = (typeof logo !== 'undefined' || logo !== "") && (typeof logo !== 'string' || !(logo instanceof String))
                  if (uploadImg) {
                      console.log("creating file" + that.newEducation.logo.name)
                      that.$store.dispatch("createEducationImg", that.newEducation.logo).then((_response) => {
                          console.log("createEducationImg _response", _response)
                          var img_url = _response.data.img_url
                          that.newEducation.img_url = img_url
                          that.createEducation2(ff[0].id)
                      });
                  } else {
                    that.createEducation2(ff[0].id)
                  }

                })
              }, 1000);

            });
        },
        updateSuggestions(txt) {
            var ff = this.categories.filter(comp => {
                return (
                    comp.name
                        .toString()
                        .toLowerCase()
                        .indexOf(txt.toLowerCase()) >= 0
                )
            })
            this.suggestCategories = ff
        },
    },

    computed: {
        user() {
            return this.$store.getters['getUser']
        }, 
        categories() {
            return this.$store.getters['getCategories']
        }, 
        partners() {
            return this.$store.getters['getPartners']
        },
    },
    mounted() {
      this.$store.dispatch("fetchCategories");
      this.$store.dispatch("fetchPartners");
    }
}
</script>